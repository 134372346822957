import { Controller } from "@hotwired/stimulus"
import { Loggable } from "./concerns/loggable"
import { ShowHide }   from "./concerns/showHide";

export default class extends Controller {

  static targets = ["confirmationMessage", "body"]

  connect() {
    Loggable(this, { debug: false })
    ShowHide(this)
    this.log("connected")
  }

  submit(event) {
    this.log("submit")
    this.element.requestSubmit() // this will trigger the form submission AND works with Turbo
    event.preventDefault()
  }

  hideConfirmationMessage(event){
    event.preventDefault()
    if (this.hasConfirmationMessageTarge) {
      this.hide(this.confirmationMessageTarget)
    }
  }

  preventDefault(event) {
    this.log("preventDefault")
    event.preventDefault()
  }

  /* as content grows, adjust the height of the textarea */
  resizeBody(event){
    this.log("resizeBody")
    this.bodyTarget.style.height = "auto"
    this.bodyTarget.style.height = (this.bodyTarget.scrollHeight) + "px"
  }

}
